<template>
  <div>
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>
    <b-row>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <b-form-group
          label="Buyers"
        >
          <custom-v-select
            v-model="filters.buyer"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="buyerOptions"
            label="text"
            placeholder="Select Buyers"
            multiple
            :disabled="filterLoader || loading"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :reduce="(data => data.id)"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <b-form-group
          label="Areas"
        >
          <custom-v-select
            v-model="filters.area"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="areaOptions"
            label="text"
            placeholder="Select Areas"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :reduce="data => data.text"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <b-form-group
          label="Salesmen"
        >
          <custom-v-select
            v-model="filters.salesman"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="salesmanOptions"
            label="text"
            placeholder="Select salesmen"
            multiple
            :disabled="filterLoader || loading"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :reduce="(data => data.text)"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <b-form-group
          label="Status"
        >
          <select
            v-model="filters.salesCallDone"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="todaySaleScheduleOptions"
            label="text"
            placeholder="Select Status"
            :disabled="filterLoader || loading"
            :reduce="(data => data.value)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BAlert,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'

export default {
  components: {
    BRow, BCol, BFormGroup, CustomVSelect, BAlert,
  },
  props: {
    value: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      filterLoader: false,
      buyerOptions: [],
      areaOptions: [],
      salesmanOptions: [],
      errorMessage: null,
      todaySaleScheduleOptions: [{ text: 'Done', value: true }, { text: 'Pending', value: false }],
    }
  },
  computed: {
    filters: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  created() {
    this.fetchFilterData()
  },
  methods: {
    async fetchFilterData() {
      try {
        this.filterLoader = true
        this.errorMessage = null
        const buyerResponse = await axios.get('/accounts/buyers_options')
        this.buyerOptions = buyerResponse.data.data

        const salesmanResponse = await axios.get('/accounts/salesmans_options')
        this.salesmanOptions = salesmanResponse.data.data

        const areaResponse = await axios.get('/accounts/areas_options')
        this.areaOptions = areaResponse.data.data
      } catch (error) {
        this.errorMessage = 'Failed to fetch filter data!'
      } finally {
        this.filterLoader = false
      }
    },
  },
}
</script>
