<template>
  <b-card
    title="Sales Schedule"
  >
    <SalesScheduleFilters v-model="filters" />

    <div class="text-right mb-1">
      <b-button
        variant="outline-primary"
        :disabled="loading || !doneList.length || updateLoader"
        class="mr-1"
        @click="markAsDone"
      >
        Mark As Done
        <b-spinner
          v-if="updateLoader"
          label="Small Spinner"
          variant="primary"
          small
        />
      </b-button>
      <b-button
        variant="outline-primary"
        :disabled="loading"
        @click="fetchSalesSchedule"
      >
        Refresh
      </b-button>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>
    <b-alert
      variant="danger"
      :show="updateError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ updateError }}
        </p>
      </div>
    </b-alert>
    <b-table
      v-if="!(loading || loadingError)"
      :items="filteredItems"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      show-empty
      class="compact-table"
      sticky-header="calc(100vh - 190px)"
    >
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelected"
          :disabled="filteredItems.length === 0 || doneSellsCallsData.length === filteredItems.length"
          @change="handleSelectAllChange"
        />
      </template>
      <template #cell(order_schedule)="data">
        <div class="text-wrap">
          {{ data.item.order_schedule }}
        </div>
      </template>
      <template #cell(order_remarks)="data">
        <div class="text-wrap">
          {{ data.item.order_remarks }}
        </div>
      </template>
      <template #cell(select)="data">
        <div v-if="data.item.sales_call_done">
          <feather-icon
            icon="CheckIcon"
            size="18"
            class="text-success"
          />
        </div>
        <b-form-checkbox
          v-else
          v-model="doneList"
          :value="data.item.buyer_id"
        />
      </template>
      <template #cell(total_bill_amount)="{item}">
        {{ formateNumber(item.total_bill_amount) }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BTable, BAlert, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import axios from 'axios'
import SalesScheduleFilters from '@/components/Sales/SalesSchedule/SalesScheduleFilters.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatMixin from '@/mixins/formatMixin'

export default {
  components: {
    BCard, BSpinner, BTable, BAlert, BButton, BFormCheckbox, SalesScheduleFilters,
  },
  mixins: [formatMixin],
  data() {
    return {
      loading: true,
      loadingError: null,
      items: [],
      tableFields: [
        { key: 'select' },
        { key: 'buyer', label: 'Buyer', sortable: true },
        { key: 'area', label: 'Area', sortable: true },
        { key: 'order_schedule', label: 'Order Schedule', sortable: true },
        { key: 'salesman', label: 'Salesman', sortable: true },
        { key: 'mobile', label: 'Mobile No.', sortable: true },
        { key: 'speed_dial', label: 'Speed Dial No.', sortable: true },
        { key: 'bill_count', label: 'Bill Count', sortable: true },
        {
          key: 'total_bill_amount', label: 'Total Bill Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'order_remarks', label: 'Order Remarks', sortable: true },
      ],
      doneList: [],
      sortBy: 'buyer',
      sortDesc: true,
      filters: {
        buyer: null, area: null, salesman: null, salesCallDone: null,
      },
      allSelected: false,
      updateLoader: false,
      updateError: null,
    }
  },
  computed: {
    filteredItems() {
      let updatedItems = [...this.items]
      const {
        buyer, salesman, salesCallDone, area,
      } = this.filters

      if (salesCallDone !== null) {
        updatedItems = updatedItems.filter(item => item.sales_call_done === salesCallDone)
      }

      if (buyer && buyer.length) {
        updatedItems = updatedItems.filter(item => buyer.includes(item.buyer_id))
      }

      if (salesman && salesman.length) {
        updatedItems = updatedItems.filter(item => salesman.includes(item.salesman))
      }

      if (area && area.length) {
        updatedItems = updatedItems.filter(item => area.includes(item.area))
      }
      return updatedItems
    },
    doneSellsCallsData() {
      return this.filteredItems.filter(data => data.sales_call_done)
    },
  },
  watch: {
    doneList(newVal) {
      if (this.filteredItems.length > 0 && newVal.length === this.filteredItems.length) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    },
  },
  created() {
    this.fetchSalesSchedule()
  },
  methods: {
    async fetchSalesSchedule() {
      try {
        this.loading = true
        const response = await axios.get('/accounts/sales_schedule')
        this.items = response.data.data

        this.loadingError = null
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Failed to fetch data!'
      } finally {
        this.loading = false
      }
    },
    handleSelectAllChange(checked) {
      this.doneList = checked ? this.filteredItems.map(item => item.buyer_id) : []
    },
    async markAsDone() {
      try {
        this.updateLoader = true
        const response = await axios.post('accounts/update_sales_status/', {
          buyers_id: this.doneList,
        })
        await this.fetchSalesSchedule()
        this.updateError = null
        this.doneList = []
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response?.data?.message || 'Status Updates Successfully!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.updateError = error?.response?.data?.message || 'Failed update schedule!'
      } finally {
        this.updateLoader = false
      }
    },
  },
}
</script>
